<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <select v-model="search_data.department_id" v-validate="{ required: true }"  id="department_id" name="department_id" class="form-control form-control-sm">
                <option selected disabled :value="''">Select a department</option>
                <option v-for="(department, index) in departments" :key="index" :value="department.id">{{ department.department_name }}</option>
              </select>
              <div class="invalid-feedback">Department is required</div>
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="getOrganizationCharts()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
<!--    <a-button type="primary" class="text-center mr-2" :loading="loading"  @click.prevent="printThis">Print</a-button>-->
    <div ref="printContent" id="printContent" v-if="organization_chart !== null && Object.keys(organization_chart).length !== 0">
      <org-chart :datasource="organization_chart">
        <template slot-scope="{ nodeData }">
          <!-- feel free to customize the internal structure of node -->
          <div class="node">
              <div class="title">
                {{ nodeData.name }}
              </div>
              <div class="content">
                <p>{{ nodeData.designation }}</p>
                <p>{{ nodeData.phone_no }}</p>
                <p>{{ nodeData.location }}</p>
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>
                      <template v-if="nodeData.assigned_locations">
                        <ul class="list-group" v-for="(assigned_location, index) in nodeData.assigned_locations" v-bind:key="index">
                          <li>{{ index + 1 }}. {{ assigned_location }}</li>
                        </ul>
                      </template>
                      <template v-else>
                        No location assigned
                      </template>
                    </span>
                  </template>
                  <p class="assigned-location">Assigned Location</p>
                </a-tooltip>
              </div>
          </div>
        </template>
      </org-chart>
    </div>
    <div v-else>
      <h5 class="text-center">No data Found</h5>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import OrgChart from 'vue-organization-chart'
import html2canvas from 'html2canvas'
// import 'vue-organization-chart/dist/orgchart.css'

export default {
  name: 'index',
  components: { OrgChart },
  data() {
    return {
      departments: [],
      organization_chart: {},
      loading: false,
      btnLoading: false,
      search_data: {
        department_id: '',
      },
    }
  },
  mounted() {
    this.getCode()
  },
  methods: {
    getCode() {
      apiClient.get('api/employee/codes')
        .then(response => {
          const data = response.data
          this.departments = data.departments
        })
        .catch(error => {
          console.log(error)
        })
    },
    getOrganizationCharts() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.btnLoading = true
          apiClient.post('api/organization-chart/search', this.search_data)
            .then(response => {
              this.btnLoading = false
              this.organization_chart = response.data.organization_chart
            })
            .catch(error => {
              console.log(error)
              this.btnLoading = false
            })
        }
      })
    },
    async printThis() {
      document.querySelector('#printContent').style.overflow = 'visible'
      window.scrollTo(0, 0)
      const el = this.$refs.printContent
      const options = {
        type: 'dataURL',
        allowTaint: true,
        useCORS: true,
        logging: false,
        width: window.outerWidth + window.innerWidth,
        height: window.outerHeight + window.innerHeight,
      }
      console.log(options)
      const printCanvas = await html2canvas(el, options)

      const link = document.createElement('a')
      link.setAttribute('download', 'download.png')
      link.setAttribute(
        'href',
        printCanvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream'),
      )
      link.click()
    },
  },
}
</script>

<style scoped>
.orgchart .node .title .fa{
  display: none !important;
}
.orgchart .node .title i{
  display: none !important;
}

.orgchart .node .content {
  white-space: pre-line !important;
  height: 80px !important;
}

.fa-group:before, .fa-users:before {
  content: "";
}

.node .content p {
  margin: 0px !important;
}

.orgchart .node .assigned-location {
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000000;
  border-top: 1px solid rgb(217 83 79 / 80%);
}

.ant-tooltip-inner {
  color: yellow !important;
  background-color: green !important;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow, .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow, .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  border-bottom-color: green !important;
}

.ant-tooltip-arrow::before{
  background-color: white !important;
}

.ant-tooltip-arrow-content{
  background-color: red !important;
}

@import 'orgchart.css';
</style>
